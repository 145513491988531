<template>
    <GenericPage
        class="review_settings"
        :headerProps="{
            title: $t('cp__settings__general__title'),
            subtitle: $t('cp__settings__general__subtitle'),
            disableBackButton: true,
        }"
        :loading="isLoading"
    >
        <SettingsForm v-if="!isLoading" class="settings_form">
            <SettingsBlock
                class="settings_block"
                :title="$t('cp__settings__general__communications__title')"
            >
                <SettingsSection class="communications_settings_fieldset" label="">
                    <label class="subtitle3">
                        {{ $t("cp__settings__general__communications__name_title") }}</label
                    >
                    <label class="body2">
                        <span
                            v-html="
                                $t('cp__settings__general__communications__name_body_part1', {
                                    name: accountName,
                                })
                            "
                        ></span>
                    </label>
                    <label class="body2"
                        ><span
                            v-html="$t('cp__settings__general__communications__name_body_part2')"
                        ></span
                    ></label>
                    <RadioList
                        class="name_radio_button"
                        v-model="localGeneralSettings.useDisplayName"
                        required
                        enableExternalValidation
                        :options="[
                            {
                                value: false,
                                displayValue:
                                    $t('cp__settings__general__communications__name_use_label') +
                                    ' ' +
                                    activeAccount.name,
                            },
                            {
                                value: true,
                                displayValue: $t(
                                    'cp__settings__general__communications__name_use_different_name_label'
                                ),
                            },
                        ]"
                    />
                    <TextInput
                        :label="
                            $t(
                                'cp__settings__general__communications__name_use_different_name_input_label'
                            )
                        "
                        class="different_name_input"
                        v-if="localGeneralSettings.useDisplayName"
                        v-model="localGeneralSettings.displayName"
                        enableExternalValidation
                        :externalValidationError="$v.localGeneralSettings.displayName.$error"
                        :externalValidationErrorMessage="
                            resolveErrorMessage($v.localGeneralSettings.displayName)
                        "
                    />
                </SettingsSection>
            </SettingsBlock>
            <SettingsBlock
                class="settings_block"
                :title="$t('cp__settings__general__notifications__title')"
            >
                <label class="body2 emails_legend">
                    <span>{{ $t("cp__settings__general__notifications__add_email_text") }}</span>
                </label>
                <div class="emails_container">
                    <EmailNotificationsAccordion
                        v-for="(item, index) in localGeneralSettings.notificationSettings"
                        :key="item.id"
                        :emailWithSettings="item"
                        @deleteItem="deleteEmail(index)"
                        :enabledProductReviews="isNewProductReviewEnabled"
                    />
                </div>
                <div class="emails_addnew" v-if="isAddingEmail">
                    <TextInput
                        v-model="userEmailInput"
                        validationType="email"
                        inputType="email"
                        label="Email"
                        enableExternalValidation
                        :externalValidationError="$v.userEmailInput.$error"
                        :externalValidationErrorMessage="resolveErrorMessage($v.userEmailInput)"
                    />
                    <Button
                        :buttonHeight="56"
                        @click.native="confirmAddingEmail()"
                        :disabled="!userEmailInput"
                    >
                        {{ $t("ADD") }}
                    </Button>
                    <Button
                        :buttonHeight="56"
                        buttonStyle="secondary"
                        @click.native="resetAddingEmail"
                    >
                        {{ $t("cp__generic__cancel") }}
                    </Button>
                </div>
                <div v-else class="add_link" @click="enterNewEmail">
                    <span>{{
                        localGeneralSettings.notificationSettings.length === 0
                            ? $t("cp__add_first_label")
                            : $t("cp__add_another_label")
                    }}</span>
                    <FontAwesomeIcon :icon="['far', 'plus']" class="add_link_icon" />
                </div>
                <div
                    v-if="localGeneralSettings.notificationSettings.length >= 1"
                    class="save_warning_text_box"
                >
                    <label class="body2 emails_legend">
                        <span v-html="$t('cp__settings__general__notifications__infotext')"></span>
                    </label>
                </div>
            </SettingsBlock>

            <SettingsBlock
                class="settings_block"
                :title="$t('cp__settings__general__reviews__title')"
            >
                <SettingsSection
                    class="settings_fieldset"
                    :label="$t('cp__settings__general__reviews__product_review_image__title')"
                    v-if="isNewProductReviewEnabled"
                >
                    <label class="body2">{{
                        $t("cp__settings__general__reviews__product_review_image__label")
                    }}</label>
                    <Checkbox
                        v-model="localGeneralSettings.productReviewImageUploadEnabled"
                        :label="
                            $t('cp__settings__general__reviews__product_review_image__checkbox')
                        "
                    />
                </SettingsSection>
                <SettingsSection
                    class="settings_fieldset"
                    :label="$t('cp__settings__general__reviews__response_review_email__title')"
                >
                    <label class="body2">{{
                        $t("cp__settings__general__reviews__response_review_email__label")
                    }}</label>
                    <Checkbox
                        v-model="localGeneralSettings.companyResponseNotificationEnabled"
                        :label="
                            $t('cp__settings__general__reviews__response_review_email__checkbox')
                        "
                    />
                </SettingsSection>
            </SettingsBlock>
        </SettingsForm>
        <SettingsFooter>
            <div class="button_group">
                <Button
                    @click.native="cancelChangedSettings"
                    :disabled="isDirty"
                    buttonStyle="secondary"
                >
                    {{ $t("cp__generic__cancel") }}
                </Button>
                <Button @click.native="saveSettings" :loading="isSaving" :disabled="isDirty">
                    {{ $t("portal instellingen (button)") }}
                </Button>
            </div>
        </SettingsFooter>
        <ConfirmPopup
            v-if="
                popup === popupOptions.USER_DELETE_EMAIL || popup === popupOptions.USER_NEED_TO_SAVE
            "
            :title="$t('cp__generic__warning')"
            :message="
                popup === popupOptions.USER_DELETE_EMAIL
                    ? $t('cp__settings__general__notifications__delete_message')
                    : $t('cp_settings__general__notifications__no_save_message')
            "
            :confirm-btn="
                popup === popupOptions.USER_DELETE_EMAIL
                    ? $t('cp_settings__general__notifications__confirm_save_button')
                    : $t('cp__settings__general__notifications__popup__save_button')
            "
            :cancel-btn="$t('cp__general__cancel')"
            @confirm="saveSettings()"
            @cancel="closeConfirmDialog"
        />
    </GenericPage>
</template>

<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faPlus } from "@fortawesome/pro-regular-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";
import { mapGetters } from "vuex";
import deepEqual from "deep-equal";
import cloneDeep from "lodash.clonedeep";
import ConfirmPopup from "@feedbackcompany/feedback-company-vue-components/src/components/organisms/ConfirmPopup.vue";
import RadioList from "@feedbackcompany/feedback-company-vue-components/src/components/atoms/Inputs/RadioList.vue";
import TextInput from "@feedbackcompany/feedback-company-vue-components/src/components/atoms/Inputs/TextInput.vue";
import Button from "@feedbackcompany/feedback-company-vue-components/src/components/atoms/Button.vue";
import Checkbox from "@feedbackcompany/feedback-company-vue-components/src/components/atoms/Inputs/Checkbox.vue";
import * as Sentry from "@sentry/vue";
import { resolveErrorMessage, validationMixin } from "@/validation";
import GenericPage from "@/components/Global/Molecules/GenericPage.vue";
import SettingsBlock from "@/components/Settings/Molecules/SettingsBlock.vue";
import SettingsSection from "@/components/Settings/Molecules/SettingsSection.vue";
import SettingsForm from "@/components/Settings/Molecules/SettingsForm.vue";
import SettingsFooter from "@/components/Settings/Molecules/SettingsFooter.vue";
import EmailNotificationsAccordion from "@/components/Settings/Molecules/EmailNotificationsAccordion.vue";
import { getGeneralSettingsSchema, getNewEmailSchema } from "@/validation/schemas/generalSettings";
import getReviewSettings from "../../graphql/settings/getReviewSettings.gql";
import updateReviewSettings from "../../graphql/settings/updateReviewSettings.gql";

library.add(faPlus);

export default {
    name: "GeneralSettings",
    mixins: [validationMixin],
    components: {
        GenericPage,
        SettingsBlock,
        SettingsSection,
        SettingsForm,
        SettingsFooter,
        Checkbox,
        TextInput,
        Button,
        RadioList,
        EmailNotificationsAccordion,
        FontAwesomeIcon,
        ConfirmPopup,
    },
    data() {
        return {
            isLoading: true,
            isSaving: false,
            localGeneralSettings: {},
            initialLocalGeneralSettings: {},
            isAddingEmail: false,
            deleteIndex: null,
            userGoTo: "",
            userEmailInput: "",
            popup: null,
            popupOptions: {
                USER_DELETE_EMAIL: "USER_DELETE_EMAIL",
                USER_NEED_TO_SAVE: "USER_NEED_TO_SAVE",
            },
        };
    },
    validations() {
        return {
            ...getGeneralSettingsSchema(this.localGeneralSettings),
            ...getNewEmailSchema(this.localGeneralSettings),
        };
    },
    computed: {
        ...mapGetters({
            activeAccount: "AccountStore/activeAccount",
            accountName: "AccountStore/accountName",
        }),
        isDirty() {
            return deepEqual(this.localGeneralSettings, this.initialLocalGeneralSettings);
        },
        userNeedToSave() {
            return deepEqual(
                this.localGeneralSettings.notificationSettings,
                this.initialLocalGeneralSettings.notificationSettings
            );
        },
        isNewProductReviewEnabled() {
            return this.activeAccount.permissions?.products?.productReviewsEnabled;
        },
    },
    watch: {
        // eslint-disable-next-line func-names
        "localGeneralSettings.useDisplayName": function (newValue, oldValue) {
            if (!newValue && oldValue != undefined) {
                this.localGeneralSettings.displayName = null;
            }
        },
    },
    methods: {
        resolveErrorMessage,
        async saveSettings() {
            if (this.deleteIndex === null) {
                this.isSaving = true;

                const {
                    companyResponseNotificationEnabled,
                    productReviewImageUploadEnabled,
                    notificationSettings,
                    useDisplayName,
                    displayName,
                } = this.localGeneralSettings;

                try {
                    const { data } = await this.$apollo.mutate({
                        mutation: updateReviewSettings,
                        variables: {
                            customerName: this.activeAccount.slug,
                            input: {
                                notificationSettings,
                                companyResponseNotificationEnabled: Boolean(
                                    companyResponseNotificationEnabled
                                ),
                                productReviewImageUploadEnabled: Boolean(
                                    productReviewImageUploadEnabled
                                ),
                                useDisplayName: Boolean(useDisplayName),
                                displayName,
                            },
                        },
                    });
                    this.mapResultsToState(data.updateReviewSettings);

                    this.$v.$reset();

                    await this.$store.dispatch("pushNotification", {
                        type: "success",
                        title: this.$t("cp__pn__config_review__success__title"),
                        message: this.$t("cp__pn__config_review__success__message"),
                    });
                } catch (error) {
                    Sentry.captureException(error);
                    await this.triggerFormErrorNotification();
                }

                this.isSaving = false;
                this.popup = null;
                // only router push if user has: need to save warning
                if (this.popup === this.popupOptions.USER_NEED_TO_SAVE || this.deleteIndex >= 0) {
                    this.$router.push(this.userGoTo);
                    this.popup = null;
                }
                // if user delete a notification set popup active from save button
            } else {
                this.popup = this.popupOptions.USER_DELETE_EMAIL;
                this.deleteIndex = null;
            }
        },
        async getSettings() {
            const { data } = await this.$apollo.query({
                query: getReviewSettings,
                variables: { customerName: this.activeAccount.slug },
                fetchPolicy: "network-only",
            });

            this.mapResultsToState(data.settings.reviewSettings);

            this.isLoading = false;
        },
        cancelChangedSettings() {
            this.localGeneralSettings = cloneDeep(this.initialLocalGeneralSettings);
        },
        enterNewEmail() {
            this.isAddingEmail = true;
        },
        resetAddingEmail() {
            this.isAddingEmail = false;
            this.userEmailInput = "";
        },
        confirmAddingEmail() {
            this.$v.$touch();
            if (!this.$v.$invalid) {
                const newNotifRecipient = {
                    email: this.userEmailInput,
                    onHold: true,
                    companyReviewApproved: true,
                    productReviewApproved: true,
                };
                this.localGeneralSettings.notificationSettings.push(newNotifRecipient);
                this.resetAddingEmail();
            }
        },
        closeConfirmDialog() {
            this.popup = null;
        },
        deleteEmail(index) {
            this.localGeneralSettings.notificationSettings.splice(index, 1);
            this.deleteIndex = index;
            this.popup = null;
        },
        mapResultsToState(settings) {
            const { notificationSettings } = cloneDeep(settings);
            this.localGeneralSettings = {
                notificationSettings: notificationSettings.map(({ __typename, ...rest }) => rest),
                productReviewImageUploadEnabled: settings.productReviewImageUploadEnabled,
                companyResponseNotificationEnabled: settings.companyResponseNotificationEnabled,
                useDisplayName: settings.useDisplayName,
                displayName: settings.displayName,
            };
            this.initialLocalGeneralSettings = cloneDeep(this.localGeneralSettings);
        },
        async triggerFormErrorNotification() {
            await this.$store.dispatch("pushNotification", {
                type: "error",
                title: this.$t("cp__pn__config_invitation__error__title"),
                message: this.$t("cp__pn__config_review__error__message"),
            });
        },
    },
    async mounted() {
        await this.getSettings();
    },
    beforeRouteLeave(to, from, next) {
        if (
            // check if notifications has any changes that needs to be saved
            this.userNeedToSave === false
        ) {
            this.userGoTo = to.path;
            this.popup = this.popupOptions.USER_NEED_TO_SAVE;
        } else {
            next(true);
        }
    },
};
</script>

<style lang="scss" scoped>
@import "../../style_variables/style_variables.scss";
@import "~include-media";

.settings_form {
    width: 100%;
    max-width: 950px;
}

.settings_block {
    width: 100%;
    margin-top: 40px;

    &:first-of-type {
        margin-top: 40px;
    }

    &:last-of-type {
        margin-bottom: 170px;
    }
}

.settings_fieldset * {
    display: inline-block;
    margin-bottom: 16px;
}

.emails_legend {
    display: inline-block;
    margin-top: 28px;
}
.emails_container {
    margin-top: 24px;
    display: flex;
    flex-direction: column;
    gap: 16px;
}
.emails_addnew {
    margin-top: 24px;
    display: flex;
    gap: 8px;
    max-width: 600px;
}
.add_link {
    font-size: 14px;
    font-family: "Open Sans", sans-serif;
    display: inline-flex;
    gap: 4px;
    align-items: center;
    color: $blue;
    cursor: pointer;
    margin-top: 16px;
    &:hover {
        color: $blue_sapphire;
    }
}

.communications_settings_fieldset * {
    display: inline-block;
    margin-bottom: 8px;
}

.name_radio_button {
    display: inline-grid;
    margin-bottom: 0px;
}
.different_name_input {
    padding-left: 30px;
}

.button_group {
    display: flex;
    gap: 20px;
}
</style>
