<template>
    <Accordion>
        <template v-slot:header>
            <div class="header">
                <FontAwesomeIcon class="header_icon" :icon="['fal', 'envelope']" />
                <span class="header_title">{{ emailWithSettings.email }}</span>
            </div>
        </template>
        <div class="content">
            <div class="content_banner">
                <span class="content_banner_legend">Email</span>
                <p class="content_banner_bodytext">{{ emailWithSettings.email }}</p>
            </div>
            <div class="content_switches">
                <!-- eslint-disable -->
                <div class="content_switches_item">
                    <div class="content_switches_item_container">
                        <SwitchInput
                            v-model="emailWithSettings.companyReviewApproved"
                            :small="true"
                        />
                    </div>
                    <span class="content_switches_item_label">{{
                        $t("cp__settings__general__notifications__company_label")
                    }}</span>
                </div>
                <div class="content_switches_item" v-if="enabledProductReviews">
                    <div class="content_switches_item_container">
                        <SwitchInput
                            v-model="emailWithSettings.productReviewApproved"
                            :small="true"
                        />
                    </div>
                    <span class="content_switches_item_label">{{
                        $t("cp__settings__general__notifications__product_label")
                    }}</span>
                </div>
                <div class="content_switches_item">
                    <div class="content_switches_item_container">
                        <SwitchInput v-model="emailWithSettings.onHold" :small="true" />
                    </div>
                    <span class="content_switches_item_label">{{
                        $t("cp__settings__general__notifications__on_hold_label")
                    }}</span>
                </div>
                <!-- eslint-enable -->
            </div>
            <div class="content_actions">
                <span class="content_actions_link" @click="emitDelete">
                    <FontAwesomeIcon
                        class="content_actions_link_icon"
                        :icon="['far', 'trash-alt']"
                    />
                    <span class="content_actions_link_text">{{ $t("cp__generic__delete") }}</span>
                </span>
            </div>
        </div>
    </Accordion>
</template>

<script>
import Accordion from "@feedbackcompany/feedback-company-vue-components/src/components/molecules/Accordion.vue";
import SwitchInput from "@feedbackcompany/feedback-company-vue-components/src/components/atoms/Inputs/SwitchInput.vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faEnvelope } from "@fortawesome/pro-light-svg-icons";
import { faTrashAlt } from "@fortawesome/pro-regular-svg-icons";

library.add(faEnvelope, faTrashAlt);

export default {
    name: "EmailNotificationsAccordion",
    components: {
        Accordion,
        FontAwesomeIcon,
        SwitchInput,
    },
    props: {
        emailWithSettings: {
            type: Object,
            required: true,
        },
        enabledProductReviews: {
            type: Boolean,
            default: true,
        },
    },
    methods: {
        emitDelete() {
            this.$emit("deleteItem");
        },
    },
};
</script>

<style lang="scss">
@import "../../../style_variables/style_variables.scss";

.header {
    display: flex;
    align-items: center;
    gap: 15px;
    font-size: 14px;
    &_icon {
        font-size: 18px;
        color: $grey_mid;
    }
}

.content {
    font-size: 14px;
    &_banner {
        border: 1px solid $grey_mischka;
        border-radius: 4px;
        padding: 6px 12px 8px;
        &_legend {
            color: $blue;
            font-size: 12px;
        }
        &_bodytext {
            color: $grey_manatee;
            margin: 0;
            line-height: 24px;
        }
    }
    &_switches {
        margin: 16px 0 24px;
        max-width: 420px;
        display: flex;
        flex-direction: column;
        gap: 16px;
        &_item {
            display: flex;
            gap: 14px;
            &_container {
                flex-grow: 1;
            }
            &_label {
                line-height: 20px;
            }
        }
    }
    &_actions {
        border-top: 1px solid $grey_athens;
        padding-top: 14px;
        &_link {
            cursor: pointer;
            color: $red;
            display: inline-flex;
            align-items: center;
            gap: 8px;
        }
    }
}
</style>
